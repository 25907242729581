import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import styled from "styled-components";
import * as yup from "yup";

import { TextField } from "@project/shared/src/components/atom";
import {
  ImageUpload,
  PostHeader,
  alert,
  AuthContext,
  theme,
  CreatePost,
} from "@project/shared";
import { addPost } from "../../../../services/post";
import { UploadFile } from "antd/lib/upload/interface";

import { useTranslation } from "react-i18next";

import defaultAvatar from "../../../../assets/default-avatar.jpg";
import Modal from "antd/lib/modal/Modal";

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.gray3};
`;
const Wrapper = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 24px 16px 24px 16px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 16.5px;
`;

const TextFieldWrapper = styled.div`
  overflow: hidden;
  & textarea {
    margin-right: 30px;
    padding-right: 20px;
  }
  .ant-input {
    border: none;
    margin-bottom: 12px;
  }
`;

const ErrorMessage = styled.div`
  text-align: left;
  color: ${theme.dustRed};
  margin-top: 15px;
`;

const InfoMessage = styled.div`
  text-align: left;
  color: ${theme.lightBlue};
  margin-top: 15px;
`;
const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    svg {
      background-color: ${theme.white};
      border-radius: 50%;
      padding: 2px;
      font-size: 20px;
    }
  }
`;

interface IPostForm {
  description: string;
}
interface PostProps {
  refetchUserData?: any;
  refetchSponsorData?: any;
  userImage?: string;
}

export const PostForm: React.FC<PostProps> = (props: PostProps) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([] as UploadFile[]);
  const { userImage, userName, setIsPostSucess } = useContext(AuthContext);
  const [showPostModal, setShowPostModal] = useState(false);
  const formData = {
    description: "",
  };

  const PostSchema = yup.object().shape({
    description: yup.string().max(1999, t("Max Length Reached.")),
  });

  const { mutate, isLoading } = useMutation(addPost, {
    onSuccess: () => {
      formik.resetForm();
      setIsPostSucess(true);
      alert({
        isSuccess: true,
        message: t("Post Created successfully."),
      });
      props.refetchUserData();
      props.refetchSponsorData();
      setShowPostModal(false);

      setFileList([]);
    },
    onError: () => {
      alert({
        message: "エラーが発生しました",
      });
    },
  });

  const generateFormData = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("description", formik.values.description);
    fileList.forEach((file, index) => {
      bodyFormData.append(`image${index + 1}`, file.originFileObj);
    });
    mutate(bodyFormData);
  };

  const formik = useFormik<IPostForm>({
    initialValues: formData,
    validationSchema: PostSchema,
    onSubmit: generateFormData,
    enableReinitialize: true,
  });

  return (
    <>
      <CreatePost
        onClick={() => {
          setShowPostModal(true);
        }}
        profileImage={props.userImage}
      />
      <StyledModal
        visible={showPostModal}
        onCancel={() => {
          setFileList([]);
          setShowPostModal(false);
          formik.resetForm();
        }}
        footer={null}
      >
        <MainWrapper>
          <Wrapper>
            <HeaderWrapper>
              <PostHeader
                name={userName}
                image={userImage ? userImage : defaultAvatar}
                onPostClick={generateFormData}
                loading={isLoading}
                disabled={
                  (fileList.length === 0 &&
                    formik.values.description.length === 0) ||
                  fileList.length > 3
                }
              />
            </HeaderWrapper>
            <TextFieldWrapper>
              <TextField
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                fullWidth
                type="textarea"
                rows={10}
                maxLength={2000}
                borderColor={"#F5F5F5"}
                onBlur={formik.handleBlur}
                error={formik.errors.description}
              />
            </TextFieldWrapper>
            <ImageUpload
              fileList={fileList && fileList}
              setFileList={setFileList}
            />
            {fileList.length == 3 ? (
              <InfoMessage>
                {t("Maximum Image Upload Limit Reached")}
              </InfoMessage>
            ) : (
              fileList.length > 3 && (
                <ErrorMessage>{t("You Can Only upload 3 Photos")}</ErrorMessage>
              )
            )}
          </Wrapper>
        </MainWrapper>
      </StyledModal>
    </>
  );
};
