import PrivateRoute from "../withPrivateRoute";
import {
  alert,
  TopPage,
  AuthContext,
  Loader,
  TopPageTabs,
} from "@project/shared";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  deletePost,
  getClubNewsFeed,
  getSponsorNewsFeed,
  sendComment,
  sendCommentReply,
} from "../services/post";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import { PostForm } from "../components/organisms/Post/PostForm";
import { Waypoint } from "react-waypoint";
import { getUserInfo } from "../services/user";
const LoaderWrapper = styled.div`
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const spreadArray = (data?: any[]): any[] => {
  const newArray: any[] = [];
  if (data) {
    for (const row of data) {
      const rowData = row?.data || [];
      for (const innerData of rowData) {
        newArray.push(innerData);
      }
    }
  }
  return newArray;
};

const Home = () => {
  const { t } = useTranslation();
  const { user, getUserImage, setUserImage } = useContext(AuthContext);

  const {
    mutate: onSendComment,
    isSuccess: commentSuccess,
    isLoading: sendCommentLoading,
  } = useMutation(sendComment, {
    onSuccess: () => {
      infiniteRefetch();
      fetchSponsorDatalist();
    },
  });

  const {
    mutate: onSendReply,
    isSuccess: replySuccess,
    isLoading: sendReplyLoading,
  } = useMutation(sendCommentReply, {
    onSuccess: () => {
      infiniteRefetch();
      fetchSponsorDatalist();
    },
  });

  const { mutate: onPostDelete, isLoading: deletePostLoading } = useMutation(
    deletePost,
    {
      onSuccess: () => {
        alert({ message: t("Post deleted successfully"), isSuccess: true });
        infiniteRefetch();
        fetchSponsorDatalist();
      },
    }
  );

  useEffect(() => {
    getUserImage();
  }, []);
  const { data: profileImage } = useQuery(["profile", user?.uid], getUserInfo, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (profileImage) {
      setUserImage(profileImage?.profile_image);
    }
  }, [profileImage]);

  const getCount = (allData) => {
    let sum = 0;
    for (const page of allData) {
      sum += page?.data?.length || 0;
    }
    return sum;
  };

  const {
    data: posts,
    fetchNextPage,
    refetch: infiniteRefetch,
    isFetching: isClubListFetching,
    isLoading: isClubPostLoading,
  } = useInfiniteQuery("posts", getClubNewsFeed, {
    getNextPageParam: (lg, allData) => {
      const gotCount = getCount(allData);
      const totalCount = lg.count;

      if (gotCount < totalCount) {
        return allData.length + 1;
      }
    },
    refetchOnWindowFocus: false,
  });

  const {
    data: sponsor,
    fetchNextPage: fetchNextSponsor,
    refetch: fetchSponsorDatalist,
    isLoading: isSponsorPostLoading,
    isFetching: isSponsorListFetching,
  } = useInfiniteQuery("sponsors", getSponsorNewsFeed, {
    getNextPageParam: (lg, allData) => {
      const gotCount = getCount(allData);
      const totalCount = lg.count;
      if (gotCount < totalCount) {
        return allData.length + 1;
      }
    },

    refetchOnWindowFocus: false,
  });

  return (
    <>
      {user ? (
        <>
          <PostForm
            refetchSponsorData={fetchSponsorDatalist}
            refetchUserData={infiniteRefetch}
            userImage={profileImage?.profile_image}
          />
          <TopPageTabs
            club={
              <>
                <TopPage
                  posts={spreadArray(posts?.pages)}
                  onSendComment={onSendComment}
                  sendCommentSuccess={commentSuccess}
                  sendCommentLoading={sendCommentLoading}
                  onSendReply={onSendReply}
                  sendReplySuccess={replySuccess}
                  sendReplyLoading={sendReplyLoading}
                  deletePost={onPostDelete}
                  deletePostLoading={deletePostLoading}
                  refetch={infiniteRefetch}
                  isPostFetching={isClubListFetching}
                  isPostLoading={isClubPostLoading}
                />
                <Waypoint onEnter={() => fetchNextPage()}></Waypoint>
              </>
            }
            sponsor={
              <>
                <TopPage
                  posts={spreadArray(sponsor?.pages)}
                  onSendComment={onSendComment}
                  sendCommentSuccess={commentSuccess}
                  sendCommentLoading={sendCommentLoading}
                  onSendReply={onSendReply}
                  sendReplySuccess={replySuccess}
                  sendReplyLoading={sendReplyLoading}
                  deletePost={onPostDelete}
                  deletePostLoading={deletePostLoading}
                  refetch={fetchSponsorDatalist}
                  isPostFetching={isSponsorListFetching}
                  isPostLoading={isSponsorPostLoading}
                />
                <Waypoint onEnter={() => fetchNextSponsor()}></Waypoint>
              </>
            }
          />
        </>
      ) : (
        <LoaderWrapper>
          <Loader size="large" />
        </LoaderWrapper>
      )}
    </>
  );
};

export default PrivateRoute(Home);
