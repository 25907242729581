import { API } from "@project/shared";

export const getUserInfo = async ({ queryKey }) => {
  const id = queryKey[1];
  const { data } = await API.get(`/users/${id}`);
  return data;
};

export const updateUserEmail = async (id: string, newEmail: string) => {
  await API.put(`/users/${id}`, { new_email: newEmail });
};
